import React, {Component} from "react"
import {
	Button,
	Form,
	Message,
	Header
} from 'semantic-ui-react'
import * as styles from './styles/forms.module.css'
import {
	confirmationColor,
} from '../../globalVar'

const Negosyo = require('../../../lib/negosyo')

export default class ConfirmOrder extends Component {
	state={
		orderCode:'',
		contact:'',
		otp:'',
		loggedIn:null, //might not be needed
		user:null,
		loading:false,
		orderOptions:[], //used for when user is logged to list their orders that have statusname==="ordered"
		error:'',
		success:null,
		loadingOrders:false,
		timeOutRef:null,
		errorMessage: {
			orderCode:null,
			contact:null,
			otp:null
		}
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOutRef)
	}

	componentDidMount() {

		Negosyo.isLoggedIn()
		.then((loggedIn)=>{
			if(loggedIn===true) {
				this.setState({
					loggedIn:true,
					loadingOrders:true
				})
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.setState({
						user:user.username
					})

					Negosyo.getContactList(user.username)
					.then((response)=> {
						if(response.status==="OK") {

							this.setState({
								contact:response.data[0].contact
							})
						}else {

						}
					}).catch((err)=>{

					})

					Negosyo.getOrderHistory(user.username, "Ordered")
					.then((response)=>{
						if(response.status==="OK") {
							let list = response.data
							list = list.sort( (a, b)=>{ //should find a better way to sort this
								return new Date(b.updated) - new Date(a.updated)
							})


							//this might not be needed anymore since the cart/list api filters by Ordered by default
							list = list.filter((order)=>{
								return order.statusname==="Ordered"
							})

							const temp = list.map((orders)=>{
								return {
									key:orders.ordercode,
									text:this.getOrderDisplay(orders),
									value:orders.ordercode,
									mobile:orders.contactmobile
								}
							})
							this.setState({
								loadingOrders:false,
								orderOptions:temp,
								orderCode:''
							})

						}else {
							this.setState({
								loadingOrders:false
							})
						}
					}).catch((err)=>{
						this.setState({loadingOrders:false})
					})

				}).catch((err)=>{

				})
			}else {
				this.setState({
					loggedIn:false
				})
			}
		}).catch((err)=>{

		})
	}

	handleInputChange = (e, { name, value }) => {
		value = value.replace(/\s/g,"");

		this.setState({ [name]: value })

		if(	value==='' ){
			const message = " must not be blank"
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		} else {
			const message = null
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		}
	}

	validateInput = (states) => {
		// Copied from CartSummary
		for( let key2 of Object.keys(states) ) {
			this.setState({
				[key2]:this.state[key2].trim()
			})
			states[key2] = states[key2].trim()
		}

		let check = true
		for( let key of Object.keys(states) ) {
			if(
				states[key] === ''
			) {
				check = false
				this.setState(prevState => ({
					errorMessage: {
						...prevState.errorMessage,
						[key] : " must not be blank"
					}
				}))
			} else if(key==="contact") {
				const pattern = /^(0|\+63)(8|9)([0-9]{9})$/g
				const result = pattern.test(states[key])
				if(!result) {
					check = false
					this.setState(prevState => ({
						errorMessage: {
							...prevState.errorMessage,
							contact : "must be a valid mobile number"
						}
					}))
				}
			}
		}
		return check
	}

	handleSubmit = () => {

		const temp = {
			otp:this.state.otp,
			orderCode:this.state.orderCode,
			contact:this.state.contact
		};
		const check = this.validateInput(temp)
		if(check) {

			const {
				otp,
				contact,
				orderCode,
				user,
			} = this.state
			this.setState({loading:true, error:""})
			Negosyo.confirmOrder(contact, orderCode, otp)
			.then((response)=>{
				if(response.status==="OK") {
					this.setState({
						success:"Successfully Confirmed Order",
						loading:false,
						loadingOrders:true,
						otp:''
					})
					const temp = setTimeout(()=>{
						this.setState({
							success:null
						})
					}, 5000)
					this.setState({
						timeOutRef:temp
					})
					Negosyo.getOrderHistory(user, "Ordered")
					.then((response)=>{
						if(response.status==="OK") {
							let list = response.data
							list = list.sort( (a, b)=>{ //should find a better way to sort this
								return new Date(b.updated) - new Date(a.updated)
							})

							//this might not be needed anymore since the cart/list api filters by Ordered by default
							list = list.filter((order)=>{
								return order.statusname==="Ordered"
							})

							const temp = list.map((orders)=>{
								return {
									key:orders.ordercode,
									text:this.getOrderDisplay(orders),
									value:orders.ordercode,
									mobile:orders.contactmobile
								}
							})
							this.setState({
								orderOptions:temp,
								loadingOrders:false,
								orderCode:''
							})

						}else {
							const {
								orderOptions
							} = this.state
							const temp = orderOptions.filter((order)=>{
								return order.value!==orderCode
							})
							this.setState({
								loadingOrders:false,
								orderOptions:temp,
								orderCode:''
							})
						}
					}).catch((err)=>{
						const {
							orderOptions
						} = this.state
						const temp = orderOptions.filter((order)=>{
							return order.value!==orderCode
						})
						this.setState({
							loadingOrders:false,
							orderOptions:temp,
							orderCode:''
						})
						this.setState({loadingOrders:false})
					})
				}else if(response.status==="Error") {
					this.setState({
						loading:false,
						error:"Unable to Confirm Order"
					})
				}else {
					this.setState({
						loading:false,
						error:"Unable to Confirm Order"
					})
				}
			}).catch((err)=>{
				this.setState({
					loading:false,
					error:"Unable to Confirm Order"
				})
			})
		}else {
			/*needed to show the errors when there's no input
			 *while submitting the form since error is an object and
			 *it doesn't detect the its properties changing
			 */
			this.setState({
				loading:false
			});
		}

	}

	getOrderDisplay = (orders) => {
		var datestr = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(orders.updated));

		var type = "Delivery";
		if (orders.hasOwnProperty("pickupcode")) {
			if (orders.pickupcode.indexOf("NNBUS")===0) {
				type = "Bus Shipping";
			} else {
				type = "Pick up";
			}
		}

		return orders.ordercode+" ["+type+"] Submitted "+datestr;
	};

	render() {
		const {
			loading,
			contact,
			orderCode,
			loggedIn,
			otp,
			error,
			success,
			orderOptions,
			errorMessage,
			loadingOrders
		} = this.state
		const {
			handleInputChange,
			handleSubmit
		} = this

		return (
			<React.Fragment>
				<Header className={styles.header}>
					Confirm Order
				</Header>


				<Form >

							{ loggedIn===true ? (
								<Form.Dropdown
									label="Order #*"
									placeholder="Please select order"
									options={orderOptions}
									error={errorMessage.orderCode}
									value={orderCode}
									selection
									name='orderCode'
									onChange={handleInputChange}
									className={`${styles.form} ${styles.formShortened}`}
									loading={loadingOrders}
									disabled={loadingOrders}
								/>
							):(
								<Form.Input
									label="Order #*"
									placeholder="e.g. 00001234"
									value={orderCode}
									name='orderCode'
									error={errorMessage.orderCode}
									onChange={handleInputChange}
									className={`${styles.form} ${styles.formShortened}`}
									loading={loadingOrders}
									disabled={loadingOrders}
								/>
							)}
							{loggedIn!==true && (
								<Form.Input
									label="Mobile #*"
									placeholder="(e.g. 0917XXXXXXX, +63917XXXXXXXX)"
									value={contact}
									name='contact'
									error={errorMessage.contact}
									onChange={handleInputChange}
									className={`${styles.form} ${styles.formShortened}`}
								/>
							)}


					<Form.Input
						label="Authorization Code*"
						placeholder="Authorization Code"
						value={otp}
						name='otp'
						error={errorMessage.otp}
						onChange={handleInputChange}
						className={`${styles.form} ${styles.formShortened}`}
					/>
					{success &&
						<Message positive className={styles.msg}>
							{this.state.success}
						</Message>
					}
					{error &&
						<Message negative className={styles.msg}>
							{error}
						</Message>
					}
					<Button
						color={confirmationColor}
						loading={loading}
						disabled={loading}
						onClick={handleSubmit}
						className={styles.form}
					>
						Proceed
					</Button>
				</Form>
			</React.Fragment>
		)
	}
}